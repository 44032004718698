.photo-gallery-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 3.2rem;
  margin-top: 3.2rem;
}

.photo-gallery {
  width: 75%;
  min-height: 100%;
  border: 1px lightgray dashed;
  margin: 3.2rem;
  margin: 0 auto;

  &--title {
    font-size: 3.2rem;
    text-align: center;
  }

  &--subtitle {
    margin-top: 3.2rem;
    font-size: 2.4rem;
    text-align: center;
    line-height: 1.4;
  }

  &--error {
    font-size: 2.2rem;
    text-align: center;
    margin-top: 2.4rem;
    color: var(--color-primary-dark);
  }

  &.dragging {
    background-color: #80808008;
  }

  & li {
    padding: 1.2rem;
    margin: 2rem;
    cursor: pointer;
    border: 1px lightgray solid;
    min-width: 75%;
    height: 100%;
    overflow: hidden;

    &.new-image {
      text-align: center;
      color: lightgray;
      display: flex;
      align-items: center;
      justify-content: center;
      & div {
        font-size: 3rem;
      }
    }
    & img {
      max-width: 100%;
    }
  }
}
