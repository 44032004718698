@use '/src/scss/abstracts/mixins' as *;

/***************************/
/* HERO SECTION */
/***************************/

.section-hero {
  padding: 7.2rem 0;
}

.hero {
  max-width: 120rem;
  margin: 0 auto;
  padding: 0 3.2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 9.6rem;
  align-items: center;

  @include respond(tab-port) {
    grid-template-columns: 1fr;
    align-content: center;
    justify-content: center;
    justify-items: center;
  }

  &__text-box {
    & > .heading-tertiary {
      margin-bottom: 2.1rem;
      line-height: 1.6;
      font-weight: 400;
    }
  }

  &__img-box {
    &--img {
      width: 100%;
      height: 100%;
    }
  }

  @include respond(tab-port) {
    text-align: center;
  }

  @include respond(phone) {
    padding: 0 1.2rem;
  }
}

.section-about {
  padding: 4.8rem 0;
}

.about {
  &__heading {
    margin-bottom: 4rem;
  }

  &__card {
    border: 1px solid var(--color-tint-1);
    border-radius: 2rem;
    background-color: var(--color-tint-1);
    padding: 3rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &--img {
      max-width: 40%;
      margin-bottom: 2rem;
    }

    &--name {
      margin-bottom: 1rem;
    }
  }
}

.section-product {
  padding: 4.8rem 0;
}

.product__heading {
}

.product-steps {
  display: flex;
  flex-direction: column;

  &--step-1 {
    & > .product-steps__step__img-box {
      @include respond(phone) {
        grid-row: 1;
      }
    }
  }

  &--step-3 {
    & > .product-steps__step__img-box {
      @include respond(phone) {
        grid-row: 1;
      }
    }
  }

  &--step-5 {
    & > .product-steps__step__img-box {
      @include respond(phone) {
        grid-row: 1;
      }
    }
  }

  &__step {
    margin-top: 5rem;
    &__details {
      display: flex;
      flex-direction: column;

      &--number {
        margin-bottom: 1rem;
        color: var(--color-grey-dark-3) !important;
      }

      &--title {
        margin-bottom: 2rem;
      }
    }

    &__img-box {
      display: flex;
      justify-content: center;

      &--img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
