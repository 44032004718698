@use '/src/scss/abstracts/mixins' as *;

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 9.6rem 0 2rem 0;
  border-top: 1px solid #eee;

  @include respond(small-tab) {
    padding: 6rem 0 2rem 0;
  }

  &--logo {
    display: block;
    margin-bottom: 5rem;
  }

  &__nav {
    list-style: none;
    display: flex;
    gap: 3rem;
    margin-bottom: 3.2rem;

    &--link:link,
    &--link:visited {
      color: var(--color-grey-dark-1);
      transition: all 0.3s;
    }

    &--link:hover,
    &--link:active {
      color: var(--color-shade-1);
    }
  }

  &__terms {
    list-style: none;
    display: flex;
    gap: 3rem;

    margin-bottom: 3.2rem;
  }

  &__copyright {
    display: flex;
    align-items: center;

    &--hipaa-icon {
      width: 7.2rem;
      height: 7.2rem;
      margin-right: 2rem;
    }
  }
}
