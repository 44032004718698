/*
--- 05 SHADOWS
0 2.4rem 4.8rem rgba(0, 0, 0, 0.075);

--- 06 BORDER-RADIUS

Default: 9px
Medium: 11px

--- 07 WHITESPACE

- SPACING SYSTEM (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128

*/

:root {
  --color-primary: #90024c;
  //104, 141, 177
  --color-primary-light: #a63570;
  //134, 164, 193
  --color-primary-dark: #56012e;
  //83, 113, 142

  --color-secondary: #575aff;
  --color-secondary-tint: #9a9cff;

  --color-tint-1: #f4e6ed;
  --color-tint-2: #e9ccdb;
  --color-tint-3: #a63570;

  --color-shade-1: #820244;
  --color-shade-2: #2b0117;

  --color-grey-light-1: #f9f9f9;
  --color-grey-light-2: #dad9d8;
  --color-grey-light-3: #b4b3b1;
  --color-grey-light-4: #8f8c8b;

  --color-grey-dark-1: #363331;
  --color-grey-dark-2: #575350;
  --color-grey-dark-3: #7c7977;

  --shadow-dark: 0 2rem 6rem rgba(0, 0, 0, 0.3);
  --shadow-light: 0 2rem 5re, rgba(0, 0, 0, 0.06);

  --line: 1px solid var(--color-grey-light-2);
  --line-2: 2px solid var(--color-grey-light-3);
}
