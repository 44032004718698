/*
    --- 01 TYPOGRAPHY SYSTEM

    - Font sizes (px)
    10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98

    - Font weights
    Default: 400
    Medium: 500
    Semi-bold: 600
    Bold: 700

    - Line heights
    Default: 1
    Small: 1.05
    Medium: 1.2
    Paragraph default: 1.6

    - Letter spacing
    -0.5px
    0.75px
*/

@use '/src/scss/abstracts/mixins' as *;

body {
  font-family: 'Inter', sans-serif;
  line-height: 1;
  font-weight: 400;
  color: var(--color-grey-dark-1);
}

.heading-primary,
.heading-secondary,
.heading-tertiary {
  font-weight: 700;
  color: var(--color-grey-dark-1);
  letter-spacing: 0.5px;
  font-family: 'Inter', sans-serif !important;
}

.heading-primary {
  font-size: 5.2rem;
  line-height: 1.2;
  margin-bottom: 2.4rem;

  @include respond(small-tab) {
    font-size: 4.4rem;
  }
}

.heading-secondary {
  font-size: 3.6rem;
  line-height: 1.2;
  margin-bottom: 3.2rem;

  @include respond(small-tab) {
    margin-bottom: 2.8rem;
  }
}

.heading-tertiary {
  font-weight: 500;
  font-size: 2rem;
  line-height: 1.2;
  margin-bottom: 3.2rem;
  letter-spacing: 0.75px;
}

.paragraph {
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 1.6;
  margin-bottom: 1.8rem;
  color: var(--color-grey-dark-1);
  letter-spacing: 0.75px;
}

.strike-through {
  text-decoration: line-through;
}

.text-primary-color {
  color: var(--color-primary);
}

.text-primary-color-dark {
  color: var(--color-primary-dark);
}

.text-grey-color {
  color: var(--color-grey-dark-2);
}

.text-color-white {
  color: var(--color-grey-light-1);
}

* > a {
  text-decoration: none;
}
