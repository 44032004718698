@use '/src/scss/abstracts/mixins' as *;

.container {
  max-width: 120rem;
  margin: 0 auto;
  padding: 0 3.2rem;
}

.grid {
  display: grid;
  row-gap: 9.6rem;
  column-gap: 6.4rem;

  @include respond(tab-land) {
    column-gap: 4.8rem;
    row-gap: 6.4rem;
  }

  @include respond(phone) {
    row-gap: 2rem;
  }
}

.grid:not(:last-child) {
  margin-bottom: 2rem;
}

.grid--2-cols {
  grid-template-columns: repeat(2, 1fr);

  @include respond(phone) {
    grid-template-columns: 1fr;
  }
}

.grid--3-cols {
  grid-template-columns: repeat(3, 1fr);

  @include respond(small-tab) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include respond(phone) {
    grid-template-columns: 1fr;
  }
}

.grid--4-cols {
  grid-template-columns: repeat(4, 1fr);

  @include respond(small-tab) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include respond(phone) {
    grid-template-columns: 1fr;
  }
}

.grid--5-cols {
  grid-template-columns: repeat(5, 1fr);
}

.grid--center-v {
  align-items: center;
}
