/* HELPER/SETTINGS CLASSES */

.margin-bottom-none {
  margin-bottom: 0 !important;
}

.margin-bottom-ex-sm {
  margin-bottom: 1.2rem !important;
}

.margin-bottom-sm {
  margin-bottom: 2rem !important;
}

.margin-bottom-reg {
  margin-bottom: 3.2rem !important;
}

.margin-bottom-md {
  margin-bottom: 4.4rem !important;
}

.margin-bottom-lg {
  margin-bottom: 7.2rem !important;
}

.margin-right-sm {
  margin-right: 3.2rem !important;
}

.margin-center {
  margin: 0 auto !important;
}

.center-text {
  text-align: center;
}

.border {
  width: 100%;
  margin: 2rem 0;
  border-bottom: var(--line-2);
}

strong {
  font-weight: 500;
}

//////////////////////////
// TOAST MESSAGES

.toastBody {
  font-family: 'Rubik' sans-serif;
  font-size: 2rem !important;
}
