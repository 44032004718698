@use '/src/scss/abstracts/mixins' as *;

/***************************/
/* HEADER */
/***************************/

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* Sticky header so fixed height helps */
  height: 9.6rem;
  padding: 0 4.8rem;
}

.logo {
  height: 4rem;
}

/***************************/
/* NAV */
/***************************/

.main-nav {
  @include respond(tab-port) {
    background-color: rgba(255, 255, 255, 0.95);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    transform: translateX(100%);

    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease-in;

    /* Hide Navigiation */
    /* Allows no transitions at all */
    /* display: none; */

    /* 1) Hide it visually */
    opacity: 0;

    /* 2) Make it unaccessible to mouse and keyboard */
    pointer-events: none;

    /* 3) Hide it from screen readers */
    visibility: hidden;
  }
}

.nav-open .main-nav {
  @include respond(tab-port) {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    transform: translateX(0);
  }
}

.nav-open .icon-mobile-nav[name='close-outline'] {
  @include respond(tab-port) {
    display: block;
  }
}

.nav-open .icon-mobile-nav[name='menu-outline'] {
  @include respond(tab-port) {
    display: none;
  }
}

.main-nav-list {
  list-style: none;
  display: flex;
  gap: 4.8rem;
  align-items: center;
  z-index: 999;

  @include respond(tab-land) {
    gap: 3.2rem;
  }

  @include respond(tab-port) {
    flex-direction: column;
    gap: 4.8rem;
  }
}

.main-nav-link:link,
.main-nav-link:visited {
  display: inline-block;
  text-decoration: none;
  color: #333;
  font-weight: 500;
  font-size: 1.8rem;
  transition: all 0.3s;

  @include respond(tab-port) {
    font-size: 3rem;
  }
}

.main-nav-link:hover,
.main-nav-link:active {
  color: var(--color-primary-dark);
}

.main-nav-link.nav-cta:link,
.main-nav-link.nav-cta:visited {
  display: inline-block;
  padding: 1.2rem 2.4rem;
  border-radius: 9px;
  color: #fff;
  background-color: var(--color-primary);
}

.main-nav-link.nav-cta:hover,
.main-nav-link.nav-cta:active {
  background-color: var(--color-grey-dark-2);
}

/***************************/
/* MOBILE */
/***************************/

.btn-mobile-nav {
  border: none;
  background: none;
  cursor: pointer;
  display: none;

  @include respond(tab-port) {
    display: block;
    z-index: 9999;
  }
}

.icon-mobile-nav {
  height: 4.8rem;
  width: 4.8rem;
  color: #333;
}

.icon-mobile-nav[name='close-outline'] {
  display: none;
}

/* STICKY NAVIGATION */
.sticky .header {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 6.4rem;
  padding: 2.1rem 1.6rem;

  background-color: rgba(255, 255, 255, 0.97);
  z-index: 999;
  box-shadow: 0 1.2rem 3.2rem rgba(0, 0, 0, 0.03);
}
