@use '/src/scss/abstracts/mixins' as *;

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%; //1rem = 10px, 10px/16px = 62.5%
  overflow-x: hidden;

  @include respond(tab-land) {
    font-size: 56.25%;
  }

  @include respond(tab-port) {
    /* 8px = 50% of 16px */
    font-size: 50%;
  }

  @include respond(small-tab) {
    font-size: 45%;
  }
}

body {
  position: relative;

  box-sizing: border-box;
  /* Only works if the overflow element does not have position: absolute in relation to body. The parent element of the overflow should not be the body */
  overflow-x: hidden;
}
