.list {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}

.list-item {
  display: flex;
  align-items: center;
  gap: 1.6rem;
  font-size: 1.8rem;
}

.list-icon {
  width: 3rem;
  height: 3rem;
  color: var(--color-primary);
}
