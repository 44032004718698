.legal-container {
  &__header {
    color: var(--color-grey-dark-1);
    font-weight: 500;
    font-size: 2.8rem;
    text-align: center;
    margin-bottom: 2rem;
  }
  &__text {
    color: var(--color-grey-dark-1);
    font-weight: 300;
    font-size: 1.8rem;
    text-align: justify;
    white-space: pre-wrap;
  }
}
